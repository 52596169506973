import VuetifyResource from '@kingscode/vuetify-resource';
import '@kingscode/vuetify-resource/dist/vuetify-resource.css';
import cssVars from 'css-vars-ponyfill';
import Vue from 'vue';
import VueCurrencyFilter from 'vue-currency-filter';
import './moment';

import vuetify from './vuetify';

cssVars();
Vue.use(VuetifyResource);

Vue.use(VueCurrencyFilter,
  {
    symbol: '€',
    thousandsSeparator: '.',
    fractionCount: 0,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true,
  });

export { vuetify };
