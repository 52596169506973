import moment from 'moment';
import Vue from 'vue';

Vue.filter('validDate', function (date) {
  if (date === null) {
    return 'geen';
  }

  return moment(date).format('DD-MM-YYYY');
});
