export default {
  methods: {
    getUserValue: function (key) {
      return new Promise((resolve) => {
          if (typeof window.user === 'undefined' ||
            window.user.length === 0 ||
            typeof window.user[key] === 'undefined') {
            this.$http.get('me')
              .then((response) => {
                this.setUserValue({
                  email: response.data.data.email,
                  full_name: response.data.data.full_name,
                  firstname: response.data.data.firstname,
                  file_token: response.data.data.file_token,
                  roles: response.data.data.roles,
                  current_organisation_id: response.data.data.current_organisation_id,
                });
                if (typeof key !== 'undefined') {
                  resolve(window.user[key]);
                } else {
                  resolve(window.user);
                }
              });
          } else {
            if (typeof key !== 'undefined') {
              resolve(window.user[key]);
            } else {
              resolve(window.user);
            }
          }

        },
      );
    },
    setUserValue: function (value, key) {
      if (typeof key !== 'undefined') {
        return window.user[key] = value;
      } else {
        return window.user = value;
      }
    },
  },
}
;
