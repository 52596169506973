import '@babel/polyfill';
import Vue from 'vue';
import API from './API.js';
import App from './App.vue';
import './mixins/filters/parseToNumber';
import './mixins/filters/validDate.js';
import './mixins/filters/year';
import { vuetify } from './plugins';
import './registerServiceWorker';
import router from './router';
import './scss/main.scss';

Vue.config.productionTip = false;
Vue.prototype.$http = API;

new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app');
