<template>
  <div id="app">
    <v-app id="inspire" :dark="darkTheme">
      <template v-if="appLoaded">
        <v-navigation-drawer
            v-model="drawer"
            :width="250"
            app
            clipped
            fixed
        >
          <EnergyAnalyst/>
          <v-list dense>
            <template v-for="item in items">
              <v-row
                  v-if="item.heading"
                  :key="item.heading"
                  align="center"
                  class="ma-0"
              >
                <v-col cols="6">
                  <v-subheader v-if="item.heading">
                    {{ item.heading }}
                  </v-subheader>
                </v-col>
                <v-col class="text-center" cols="6">
                  <a class="body-2 black--text" href="#!">EDIT</a>
                </v-col>
              </v-row>
              <v-list-group
                  v-else-if="item.children"
                  :key="item.text"
                  v-model="item.model"
                  :prepend-icon="item.model ? item.icon : item['icon-alt']"
                  append-icon=""
              >
                <v-list-item slot="activator">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                    v-for="(child, i) in item.children"
                    :key="i"
                    :to="{name:child.routeName}"
                    active-class="primary white--text"
                >
                  <v-list-item-action v-if="child.icon">
                    <v-icon color="primary">{{ child.icon }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ child.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
              <v-list-item v-else
                           :key="item.text"
                           :to="item.routeName ? {name:item.routeName} : null"
                           active-class="primary white--text"
                           @click="handleClick(item)">
                <v-list-item-action>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-navigation-drawer>
        <v-content>
          <v-container class="fill-height align-start" fluid>
              <router-view :key="$route.path"/>
          </v-container>
        </v-content>
      </template>
    </v-app>
  </div>
</template>

<script>
import * as Cookies from 'js-cookie';
import EnergyAnalyst from '@/components/EnergyAnalyst.vue';
import user from './mixins/user.js';

export default {
  components: { EnergyAnalyst },
  watch: {
    $route: {
      handler() {
        this.redirectIfNotLoggedIn();
      },
      deep: true,
    },
    darkTheme() {
      this.toggleDarkTheme();
    },
  },
  mixins: [user],
  data() {
    return {
      darkTheme: this.isDarkTheme(),
      isMobileApp: false,
      dialog: false,
      drawer: null,
      appLoaded: false,
      items: [
        {
          icon: 'fa-home',
          text: 'Dashboard',
          routeName: 'home',
        },
        {
          icon: 'far fa-chart-bar',
          text: 'Verbruik',
          routeName: 'usage',
        },
        {
          icon: 'far fa-clipboard-list-check',
          text: 'Acties',
          routeName: 'action',
        },
        {
          icon: 'far fa-lightbulb-dollar',
          text: 'Besparingen',
          routeName: 'saving',
        },
        {
          icon: 'far fa-question-circle',
          text: 'Help',
          url: `${window.location.protocol}//${window.location.host}/Handleiding_HIT_Solution_2020.pdf`,
        },
      ],
    };
  },
  methods: {
    /**
     * Whether the admin is dark theme.
     *
     * @returns {boolean}
     */
    isDarkTheme() {
      return Cookies.get('dark_theme') === 'true';
    },

    /**
     * Toggle the dark theme.
     *
     * @returns {void}
     */
    toggleDarkTheme() {
      Cookies.set('dark_theme', !this.isDarkTheme(), {
        sameSite: 'None',
        secure: true,
      });
    },
    handleClick(item) {
      if (!item.url) return;
      if (this.isMobileApp) {
        parent.postMessage({
          type: 'url',
          url: item.url,
        }, '*');
        return;
      }
      window.location.href = item.url;
    },
    redirectIfNotLoggedIn() {
      let hash = {};

      switch (this.$route.name) {

        case null:
          this.appLoaded = false;
          return;

        case 'oauth.callback':
          this.appLoaded = false;
          window.location.hash.substring(1).split('&')
              .map((parameter) => hash[parameter.split('=')[0]] = parameter.split('=')[1]);
          Cookies.set('access_token', hash['access_token'], {
            sameSite: 'None',
            secure: true,
          });
          window.access_token = hash['access_token'];
          this.$router.push({ name: 'home' });
          return;

        case 'oauth.redirect':
          this.appLoaded = false;
          this.redirectForOAuthFlow();
          return;

        default:
          if (typeof Cookies.get('access_token') === 'undefined' && typeof window.access_token === 'undefined') {
            this.redirectForOAuthFlow();
            return;
          }
          this.appLoaded = true;
          return;
      }
    },

    redirectForOAuthFlow() {
      window.location.href = this.buildOAuthRedirectUrl();
    },

    buildOAuthRedirectUrl() {
      let params = {
        client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
        redirect_uri: process.env.VUE_APP_OAUTH_REDIRECT_URI,
        response_type: process.env.VUE_APP_OAUTH_RESPONSE_TYPE,
        scope: process.env.VUE_APP_OAUTH_SCOPE,
      };
      let x = Object.keys(params)
          .map(key => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
          })
          .join('&');

      return process.env.VUE_APP_OAUTH_URL + '?' + x;
    },
    setAppWidthsForIphone() {
      document.getElementById('app').style.width = window.innerWidth + 'px';
    },
  },
  mounted() {
    if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
      setInterval(() => {
        this.setAppWidthsForIphone();
      }, 500);
    }
  },
  created() {
    if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
      this.setAppWidthsForIphone();
      document.getElementsByTagName('body')[0].style = 'overflow-y: scroll';
    }
    this.redirectIfNotLoggedIn();
    parent.postMessage({ type: 'canOpenMenu' }, '*');
    window.addEventListener('message', (event) => {
      if (typeof event.data === 'object' && typeof event.data.type === 'string' && event.data.type === 'openMenu') {
        this.drawer = !this.drawer;
      }
      if (typeof event.data === 'object' && typeof event.data.type === 'string' && event.data.type === 'isMobileApp') {
        this.isMobileApp = true;
      }
    });
  },
};
</script>
<style lang="scss">
html {
  height: 100%;
}

body {
  height:                     100%;
  -webkit-overflow-scrolling: touch;
}

#app {
  max-width: 100%;
}

.lightswitch {
  margin-left: 3em;
  margin-top:  20px;
}

.navigation {
  min-height: 85%;
}

.navigation-drawer {
  padding-bottom: 0;
}

.container .page {
  width: 100%;
}

.v-card.white-card {
  .v-card__title {
    background-color: var(--v-primary-base);
    color:            #ffffff;
    padding:          14px;
  }
}

.v-navigation-drawer {
  .v-list {
    padding-top: 0;
  }
}

.application.theme--light {
  .v-navigation-drawer {
    .v-list {
      padding-top: 0;
    }

    .v-list :not(.white--text) * {
      color: var(--v-primary-base)
    }

    .v-list .white--text * {
      color: #ffffff;
    }

    .v-list > div:hover {
      * {
        color: #ffffff;
      }

      background-color: var(--v-primary-base)
    }
  }
}

.container {
  width: 100%;
}

.crud-actions {
  min-width: 150px;
}

.elipsis-table table {
  table-layout: fixed;
}

.elipsis-table table td span {
  display:       block;
  overflow:      hidden;
  text-overflow: ellipsis;
  white-space:   nowrap;
}

.elipsis-table .crud-actions {
  display:     flex;
  float:       right;
  min-width:   auto;
  padding:     5px 13px !important;
  white-space: nowrap;
}

@media only screen and (max-width: 600px) {
  .elipsis-table .vuetify-resource th:first-child:not(.column) {
    width: 100% !important;
  }
}

i.v-icon {
  min-width: 30px;
}
</style>
