<template>
  <v-card elevation="0" color="accent" dark class="energy-consultant" v-if="contact !== null" tile>
    <v-row class="pt-3 ma-0">
      <v-col cols="3">
        <v-avatar class="mx-3">
          <img :src="contact.profile_image.public_path.replace('?', '/100?')" v-if="contact.profile_image"/>
        </v-avatar>
      </v-col>
      <v-col cols="9" class="pa-4">
        <p class="subheading ma-0">{{ contact.name }}</p>
        <span class="caption">{{ contact.contact.function }}</span>

        <div class="my-2">
          <v-btn class="mx-0" text :href="'tel:' + contact.contact.phone" icon>
            <v-icon>fa-phone</v-icon>
          </v-btn>
          <v-btn text :href="'mailto:' + contact.email" icon>
            <v-icon>fa-envelope</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

import { contactType } from '@/enums/contactType.js';

export default {
  name: 'EnergyAnalyst',
  data() {
    return {
      contact: null,
    };
  },
  created() {
    this.$http.get('me/organisation/current').then((response) => {
      let index = response.data.data.contacts.findIndex((obj) => {
        return obj.organisation_contact_type_id === contactType.ENERGY_ANALYST;
      });
      if (index !== -1) {
        this.contact = response.data.data.contacts[index];
      }
    });
  },
  methods: {},
};
</script>
