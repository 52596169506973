import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home.redirect',
      redirect: 'home',
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/Home'),
    },
    {
      path: '/besparingen',
      name: 'saving',
      component: () => import('@/views/Saving'),
    },
    {
      path: '/oauth/redirect',
      name: 'oauth.redirect',
      component: () => import('@/views/OAuthRedirect'),
    },
    {
      path: '/oauth/callback',
      name: 'oauth.callback',
      component: () => import('@/views/OAuthCallback'),
    },
    {
      path: '/acties',
      name: 'action',
      component: () => import('@/views/Actions'),
    },
    {
      path: '/verbruik',
      name: 'usage',
      component: () => import('@/views/Usage'),
    },
    {
      path: '/404',
      name: '404',
      component: () => import('@/views/NotFound'),
    },
    {
      path: '/403',
      name: '403',
      component: () => import('@/views/NotAllowed'),
    },
    {
      path: '*',
      redirect: '/404',
    },
  ],
});
