import '@fortawesome/fontawesome-pro/css/all.css';
import Vue from 'vue';
import nl from 'vuetify/es5/locale/nl';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);
export default new Vuetify({
  icons: {
    iconfont: 'fa',
    values: {
      'close': 'fas fa-times',
      'menu': 'fas fa-bars',
      'delete': 'fas fa-trash',
      'add': 'fas fa-plus',
      'edit': 'fas fa-pencil-alt',
      'search': 'fas fa-search',
    },
  },
  customProperties: true,
  lang: {
    locales: { nl },
    current: 'nl',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#347aaa',
        secondary: '#243164',
        accent: '#23b4b3',
      },
    },
  },
});
